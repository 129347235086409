/* Mehr Nastaliq Web Font v2 */
@font-face {
  font-family: "Mehr";
  src: url("https://cdn.jsdelivr.net/npm/mehr-nastaliq/fonts/mehr.woff2") format("woff2"),
  url("https://unpkg.com/mehr-nastaliq/fonts/mehr.woff2") format("woff2"),
  url("https://cdn.jsdelivr.net/npm/mehr@2.0.1/mehr.woff") format("woff")
  url("https://unpkg/mehr/mehr.woff") format("woff"),
  url("https://cdn.jsdelivr.net/npm/mehr@2.0.1/mehr.ttf") format("truetype"),
  url("https://unpkg.com/mehr/mehr.ttf") format("truetype");
}

/* Jameel Noori Nastaliq v4 */
@font-face {
  font-family: "Jameel-Noori.N";
  src: url("https://cdn.jsdelivr.net/npm/jameel-noori/fonts/jameel-noori-nastaleeq4.woff2") format("woff2"),
  url("https://unpkg.com/jameel-noori/fonts/jameel-noori-nastaleeq4.woff2") format("woff2");
}

/* Jameel Noori Nastaliq Kasheedah v3 */
@font-face {
  font-family: "Jameel-Noori.K";
  src: url("https://cdn.jsdelivr.net/npm/jameel-noori-nastaliq-kasheeda/fonts/JameelNooriNastaliqKasheed3.woff2") format("woff2"),
  url("https://unpkg.com/jameel-noori-nastaliq-kasheeda/fonts/JameelNooriNastaliqKasheeda3.woff2") format("woff2");
}

  /* Nafees Nastaliq */
@font-face {
  font-family: "Nafees";
  src: url("https://cdn.jsdelivr.net/npm/nafees-nastaliq/fonts/nafees-nastaliq-v1.2.woff2") format("woff2"),
  url("https://unpkg.com/nafees-nastaliq/fonts/nafees-nastaliq-v1.2.woff2") format("woff2"),
  url("https://fontlibrary.org/assets/fonts/nafees-nastaleeq/f7aa4eb888367518b563474b4f498f40/4e99406984f565d64d32a29bd46a456e/NafeesRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
  
/* Emad Nastaliq */
@font-face {
  font-family: "Emad-Nastaliq";
  src: url("https://cdn.jsdelivr.net/npm/emad-nastaliq/fonts/Emad-Nastaliq.woff2") format("woff2"),
  url("https://unpkg.com/emad-nastaliq/fonts/Emad-Nastaliq.woff2") format("woff2");
}

/* Faiz Lahori Nastaliq v2 */
@font-face {
  font-family: "Faiz-Nastaliq";
  src: url("https://cdn.jsdelivr.net/npm/faiz-nastaliq/fonts/FaizNastaliq2.woff2") format("woff2"),
  url("https://unpkg.com/faiz-nastaliq/fonts/FaizNastaliq2.woff2") format("woff2");
}

/* AlQalam_Taj_Nastaleeq */
@font-face {
  font-family: "AlQalam-Taj.N";
  src: url("https://cdn.jsdelivr.net/npm/alqalam-taj-nastaliq/fonts/alqalam-taj-nastaliq.woff2") format("woff2"),
  url("https://unpkg.com/alqalam-taj-nastaliq/fonts/alqalam-taj-nastaliq.woff2") format("woff2");
}

/* Photex-Decorative-Nastaliq */
@font-face{
  font-family: "Photex-Decorative.N";
  src: url("https://cdn.jsdelivr.net/npm/photex-decorative-nastaliq@1.0.0/fonts/photex-decorative-nastaliq.woff2") format("woff2"),
  url("https://unpkg.com/photex-decorative-nastaliq@1.0.0/fonts/photex-decorative-nastaliq.woff2") format("woff2");}

/* Photex-Nice-Nastaliq */
@font-face{
  font-family: "Photex-Nice.N";
  src:url("https://cdn.jsdelivr.net/npm/photex-nice-nastaliq/fonts/photex-nice-nastaliq.woff2") format("woff2"),url("https://unpkg.com/photex-nice-nastaliq/fonts/photex-nice-nastaliq.woff2") format("woff2")}

/* Gulzar Nastaliq */
@font-face {
  font-family: "Gulzar";
  src: url("https://fonts.gstatic.com/s/gulzar/v12/Wnz6HAc9eB3HB2I7YzYYmg8.woff2") format("woff2");
}

/* Amiri Arabic Font */
@font-face {
  font-family: "Amiri";
  src: url("https://fonts.gstatic.com/s/amiri/v27/J7aRnpd8CGxBHpUrtLMA7w.woff2") format("woff2");
}

/* body Fonts */
body {
  font-family: "Mehr", "Emad-Nastaliq", "Nafees", "Jameel-Noori.N", "Jameel-Noori.K", "Faiz-Nastaliq", "AlQalam-Taj.N", "Photex-Decorative.N", "Photex-Nice.N", "Gulzar", "Amiri";
}

.post-title {
    font-family: "Mehr", "Emad-Nastaliq", "Nafees", "Jameel-Noori.N", "Jameel-Noori.K", "Faiz-Nastaliq", "AlQalam-Taj.N", "Photex-Decorative.N", "Photex-Nice.N", "Gulzar", "Amiri";
}

.post-body {
    font-family: "Mehr", "Emad-Nastaliq", "Nafees", "Jameel-Noori.N", "Jameel-Noori.K", "Faiz-Nastaliq", "AlQalam-Taj.N", "Photex-Decorative.N", "Photex-Nice.N", "Gulzar", "Amiri";
}


/* CSS Aplly */
/* Fonts Name List: if you want to use any fonts Enter Provided Fonts Name After font-family:  */
/* font-family: "Mehr", "Emad-Nastaliq", "Nafees", "Jameel-Noori.N", "Jameel-Noori.K", "Faiz-Nastaliq", "AlQalam-Taj.N", "Photex-Decorative.N", "Photex-Nice.N", "Gulzar", "Amiri"; */